<template>
    <div>
        <TopPage title="Editar" :options="topOptions" :actions="actions" complement="usuário" />

        <div class=" p-4 bg-white border-2 rounded-2xl">
            <div class="pt-2 pb-2">
                <div class="flex justify-between">
                    <!-- <img :src="user.image" width="80" /> -->
                    <div>
                        <div v-if="loading" class="load-wraper w-16">
                            <div class="activity"></div>
                        </div>
                        <h2 v-else class="text-l flex">
                            <div class="text-gray-600 font-semibold text-2xl font-display font-light">{{ user.name }}</div>
                        </h2>
                    </div>

                    <div class="flex justify-end">
                        <button
                            @click="updateUser"
                            class="flex items-center justify-between px-6 py-2 border-2 border-gray-300 text-white font-semibold text-xs leading-tight uppercase rounded-full hover:bg-gray-200 focus:outline-none focus:ring-0 transition duration-150 ease-in-out text-gray-800"
                        >
                            Salvar

                            <svg class="ml-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <path d="M121.439,2148.224h24v24h-24Z" transform="translate(-121.439 -2148.224)" fill="none" />
                                <path
                                    d="M126.761,2168.1l14.429-6.7a1.3,1.3,0,0,0,0-2.36l-14.429-6.7a1.29,1.29,0,0,0-1.743,1.621l2.233,6.253-2.233,6.261A1.29,1.29,0,0,0,126.761,2168.1Z"
                                    transform="translate(-121.439 -2148.224)"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="1.3"
                                />
                                <line x2="14.687" transform="translate(5.811 11.996)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <div class="plans flex" v-for="plan in plans" :key="plan.id">
                <div class="border p-2 border-gray-300">
                    <div class="font-medium text-gray-800">{{ plan.name }}</div>
                    <div>
                        <label class="text-sm font-medium text-gray-600">N Cartão:</label>
                        <div>{{ plan.card_number }}</div>
                    </div>
                    <div>
                        <label class="text-sm font-medium text-gray-600">Validade:</label>
                        <div>{{ plan.valid_until }}</div>
                    </div>
                </div>
            </div>
            <div class="w-full py-4">
                <div class="mt-4 flex space-x-3">
                    <div class="w-1/2">
                        <label class="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-200">Nome</label>
                        <input class="input-primary" v-model="user.name" />
                    </div>
                    <div class="w-1/2">
                        <label class="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-200 ">E-mail</label>
                        <input class="input-primary disabled:bg-slate-50" v-model="user.email"/>
                    </div>
                </div>
                <div class="mt-4 flex space-x-3">
                    <div class="w-1/2">
                        <label class="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-200">CPF</label>
                        <input class="input-primary" v-model="user.cpf" />
                    </div>
                    <div class="w-1/2">
                        <label class="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-200">Telefone</label>
                        <input class="input-primary" v-model="user.phone" />
                    </div>
                    <div class="w-1/2">
                        <label class="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-200">Dada de nascimento</label>
                        <input class="input-primary" v-model="user.birthdate" />
                    </div>
                </div>

                <DefaultTable class="mt-4" title="Pagamentos realizados" :endpoint="`dashboard/users/${$route.params.id}/payments`" :options="tableOptions" :columns="columns" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TopPage from '@/components/TopPage.vue';
import { UserService } from '@/services/user.service';
import { ApiService } from '@/services/api.service';
import { Toast } from '@/services/toast.service';
import DefaultTable from '@/components/tables/DefaultTable.vue';
import { helpers } from '@/utils/helpers';
// import { vMaska } from 'maska';

const BRL = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
});
export default {
    name: 'Operational',
    components: {
        TopPage,
        DefaultTable
    },
    data() {
        return {
            topOptions: {
                datefilter: false,
                actions: true
            },
            columns: [
                { label: 'ID', name: 'id', orderable: true },
                {
                    label: 'Valor R$',
                    name: 'price',
                    orderable: true,
                    transform: row => {
                        return BRL.format(row.data.price);
                    }
                },
                { label: 'Tipo', name: 'payable_type', orderable: false },
                {
                    label: 'Data',
                    name: 'created_at',
                    orderable: true,
                    transform: row => {
                        console.log(row);
                        return helpers.formatDate(row.data.created_at);
                    }
                }
            ],
            tableOptions: {
                cards: false
            },
            user: {
                user_plans: []
            },
            loading: true
        };
    },
    async beforeRouteEnter(to, from, next) {
        const user = await UserService.getUserById(to.params.id);
        next(vm => {
            vm.user = user;
            vm.loading = false;
        });
    },
    computed: {
        ...mapGetters('auth', ['loggedIn']),
        actions() {
            const actions = [
                { name: 'Dados bancários', method: this.editBankData, visible: this.user.bank_account !== null },
                { name: 'Redefinir senha', method: this.resetPassword, visible: true }
            ];
            return actions.filter(action => action.visible);
        },
        plans() {
            const plans = this.user.user_plans.map(plan => {
                return {
                    id: plan.id,
                    name: plan.plan_type.name,
                    status: plan.is_active ? 'Ativo' : 'Inativo',
                    valid_until: helpers.formatDate(plan.valid_until),
                    card_number: plan.card_number
                };
            });

            return plans;
        }
        // ...mapGetters('operational', ['user'])
    },
    methods: {
        async resetPassword() {
            await ApiService.customRequest(
                {
                    url: `/dashboard/users/${this.$route.params.id}/reset`,
                    data: {},
                    method: 'POST',
                    confirmTitle: 'Deseja redefinir a senha do usuário?'
                },
                true
            )
                .then( response => {
                    if (response.status == 200) {
                        Toast.fire({
                            icon: 'success',
                            title: 'Senha foi redefinida com sucesso. A nova senha é o CPF do usuário.',
                            timer: 5000
                        });
                    }
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async updateUser() {
            this.loading = true;
            const { name, email, cpf, phone, birthdate } = this.user;
            await ApiService.customRequest(
                {
                    url: `/dashboard/users/${this.$route.params.id}`,
                    method: 'POST',
                    data: { name: name, email: email, cpf: cpf, phone: phone, birthdate: birthdate },
                    confirmTitle: 'Atualizar as informaçãoes do usuário?'
                },
                true
            )
                .then(response => {
                    if (response.status == 200) {
                      if(response.data.status == 'error') {
                        Toast.fire({
                            icon: 'error',
                            title: response.data.message,
                            timer: 5000
                        });  
                      } else { 
                        Toast.fire({
                            icon: 'success',
                            title: 'Alterações salvas com sucesso',
                            timer: 5000
                        });
                      }
                    }
                })
                .catch(error => {
                    Toast.fire({
                        icon: 'error',
                        title: error.data?.message,
                        timer: 5000
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async editBankData() {
            this.$router.push({ name: 'operational.user.bank', params: { id: this.$route.params.id } });
        }
    }
};
</script>

<style>
.load-wraper {
    position: relative;
    height: 100%;
    width: 100%;
    background-color: rgb(211, 211, 211);
    z-index: 44;
    overflow: hidden;
    border-radius: 5px;
}
.activity {
    position: absolute;
    left: -45%;
    height: 100%;
    width: 45%;
    background-image: linear-gradient(to left, rgba(251, 251, 251, 0.05), rgba(251, 251, 251, 0.3), rgba(251, 251, 251, 0.6), rgba(251, 251, 251, 0.3), rgba(251, 251, 251, 0.05));
    background-image: -moz-linear-gradient(to left, rgba(251, 251, 251, 0.05), rgba(251, 251, 251, 0.3), rgba(251, 251, 251, 0.6), rgba(251, 251, 251, 0.3), rgba(251, 251, 251, 0.05));
    background-image: -webkit-linear-gradient(to left, rgba(251, 251, 251, 0.05), rgba(251, 251, 251, 0.3), rgba(251, 251, 251, 0.6), rgba(251, 251, 251, 0.3), rgba(251, 251, 251, 0.05));
    animation: loading 1s infinite;
    z-index: 45;
}

@keyframes loading {
    0% {
        left: -45%;
    }
    100% {
        left: 100%;
    }
}
</style>
